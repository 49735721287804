<template>
  <div class="graph">

    <period :document.sync="computedPeriod" />
    <series
      class="mt-4"
      :document.sync="computedSeries"
      :targetDocTy="targetDocTy"
    />
  </div>
</template>
<script>
export default {
  components: {
    Period: () => import('./period'),
    Series: () => import('./series')
  },
  computed: {
    computedPeriod: {
      get () {
        return this.dataPeriod
      },
      set (v) {
        this.dataPeriod = v
        this.dataDocumentEmit()
      }
    },
    computedSeries: {
      get () {
        return this.dataSeries
      },
      set (v) {
        this.dataSeries = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      dataPeriod: null,
      dataSeries: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      const doc = this.lodash.cloneDeep({ period: this.dataPeriod, series: this.dataSeries })
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', doc)
      }
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        const graph = this.lodash.cloneDeep(v)
        this.dataPeriod = graph?.period
        this.dataSeries = graph?.series

        this.dataDocumentEmit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
